import PropertyApi from "../../api/property";
import ContactApi from "../../api/contact";
import { handleError } from "../../utils/ErrorHandler";
import { showErrorMessage } from "@/utils/Notification";

// initial state
const state = () => ({
  listings: [],
  property_details: {},
  error: undefined,
  loading: false
});

// getters
const getters = {};

// actions
const actions = {
  async getPropertyDetails({ commit }, { address, city, state, zip }) {
    try {
      const data = await PropertyApi.getPropertyDetails(
        address,
        city,
        state,
        zip
      );
      commit("setPropertyDetails", data);
    } catch (e) {
      commit("setError", handleError(e));
    }
  },

  async updateAddress({ commit }, { contactId, contact }) {
    // const action = 'updateAddress';
    try {
      commit("setLoading", true);
      const newContactData = await ContactApi.updateAddress(contactId, contact);
      if (newContactData) {
        commit("Contact/setContact", newContactData, { root: true });
      }
      return true;
    } catch (e) {
      showErrorMessage(handleError(e));
      throw e;
    } finally {
      commit("setLoading", false);
    }
    /*
    HomevalApi.patch(`/contact/${contactId}`, contact)
      .then(() => dispatch(setStatus(action, 'success')))
      .catch(err => {
        dispatch(setStatus(action, 'error', getErrorMessage(err)));
      });

     */
  },

  resetStatus({ commit }) {
    //dispatch(setStatus(action));
    //commit('setStatus', );
  }
};

// mutations
const mutations = {
  setPropertyDetails(state, newDetails) {
    state.property_details = newDetails;
  },
  setError(state, newError) {
    state.error = newError;
  },
  setLoading(state, newVal) {
    state.loading = newVal;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
