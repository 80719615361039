import Vue from 'vue';
import Vuex from 'vuex';

import Agent from './modules/agent';
import Contact from './modules/contact';
import Results from './modules/results';
import Property from './modules/property';
import Lists from './modules/list';
import Dashboard from './modules/dashboard';
import Tracking from './modules/tracking';
import OrderTesting from './modules/orderTesting';
import PostalLists from './modules/postalLists';
import Order from './modules/order';



Vue.use(Vuex);

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
}

const debug = process.env.NODE_ENV !== 'production';


export default new Vuex.Store({
                                state,
                                mutations,
                                modules: {
                                  Agent,
                                  Contact,
                                  Property,
                                  Results,
                                  Lists,
                                  Dashboard,
                                  Tracking,
                                  OrderTesting,
                                  PostalLists,
                                  Order,
                                },
                                strict: debug,
                                devtools: debug,
                              });