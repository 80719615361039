<template>
<div class="layout-plain">
    <router-view/>
</div>
</template>
<script>
export default {
    name: 'LayoutPlain'
}
</script>
