import BaseApi from './base-api';

class ContactApi extends BaseApi{
  constructor() {
    console.log('ContactApi constructor called');
    super();
  }

  async recordVisit(id) {
    console.log ( 'recordVisit id ', id);
    try {
      const response = await this.API.post('homevalapi',`/api/contacts/${id}/record-visit`);
      console.debug ('homevalAPI response', response);
    } catch (e) {
      throw e;
    }
  }

  async loadContactDataById (id) {
    try {
      const response = await this.API.get('homevaldmgapi', `/api/contacts/${id}`);

      return response.data.data.contact;
    } catch (e) {
      throw e;
    }
  }


  async saveAppointment(id, type, data) {
    [data.owner_firstname, data.owner_lastname = ''] = data.name.split(' ');
    await this.API.post('homevaldmgapi', `/api/contacts/${id}/${type}`, { body: data });
    return true;
  }

  async updateAddress(contactId, contact) {

    const {data:{updatedContact}} = await this.API.put('homevaldmgapi', `/api/contacts/${contactId}`, { body: contact });

    return updatedContact;

    /*
          .then(() => dispatch(setStatus(action, 'success')))
          .catch(err => {
            dispatch(setStatus(action, 'error', getErrorMessage(err)));
          });

     */
  }

  async creteContact(agentId, contact){
    const { data: { data: { contact: updatedContact } } } = await this.API.put('homevaldmgapi', '/api/contacts/widgetLead', { body: contact });

    return updatedContact;
  }
}

export default new ContactApi();