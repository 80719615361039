import BaseApi from '@/api/base-api';

// import axios from 'axios';


class ListApi extends BaseApi {
  async loadListsByAgentId(agentId) {
    const { data: { lists } } = await this.Api.get('homevaldmgapi', `/api/lists/agent/${agentId}`);
    return lists;
  }

  async updateListDataById(id, list) {
    const { data: { list: updList } } = await this.Api.put('homevaldmgapi', `/api/lists/${id}`, { body: list });

    return updList;
  }

  async updateImage(listId, file) {

    const getBase64fromFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const spliced = reader.result.split(',');
          const header = spliced[0];
          spliced.shift();
          resolve({
                    header: header,
                    body: spliced.join(''),
                    mimetype: file.type,
                    name: file.name,
                  });
        };
        reader.onerror = (err) => {
          console.log(`getBase64fromFile failed.`);
          reject(err);
        };
      });
    }


    const data = await getBase64fromFile(file);
    const { data: { list } } = await this.Api.post('homevaldmgapi', `/api/lists/change-image/${listId}`, { body: { image: data } });


    return list;

  }

}


export default new ListApi();