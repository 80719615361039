import AgentsApi from '../../api/agents'
import { showErrorMessage, showSuccessMessage } from '@/utils/Notification';
import { handleError } from '@/utils/ErrorHandler';

// initial state
const state = () => ({
  agent: {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    primary_domain: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    idx_dre: '',
    company: '',
    logo: '',
    agentphoto1_url: '',
    use_agentphoto1: true,
  },
  loading: false,
  team: true,
  sampleLink: 0,
  saveLoading: false,
})


// getters
const getters = {
  getAgent(state) {
    const { agent } = state;
    return agent;
  },
  getFacebookURL(state) {
    const { agent } = state;
    return "https://facebook.com/" + agent.facebook;
  },
  getLinkedinURL(state) {
    const { agent } = state;
    return "https://linkedin.com/in/" + agent.linkedin;
  }
}

// actions
const actions = {
  async loadAgentData({ commit, state }, { agentId }) {

    commit('setLoading', true);
    // run api call
    const agent = await AgentsApi.loadAgentDataById(agentId);
    console.log('loadAgentData agent ', agent);
    console.log('loadAgentData agentId ', agentId);
    commit('setAgent', agent);
    commit('setLoading', false);
  },

  async updateMessage({ dispatch, state }, newMessage) {
    const result = await dispatch('updateAgentData', { agent: { ...state.agent, profile_message: newMessage }, suppressMessage: true });
    result && showSuccessMessage('Profile Message was updated Successfully');
    return result;
  },

  async updateNotificationEmail({ dispatch, state }, email) {
    const result = await dispatch('updateAgentData', { agent: { ...state.agent, email }, suppressMessage: true });
    result && showSuccessMessage('Email was updated Successfully');
    return result;
  },

  async updateAgentData({ commit, state }, { agent, suppressMessage = false }) {

    commit('setSaveLoading', true);
    // run api call
    try {
      const updatedAgent = await AgentsApi.updateAgentDataById(agent.id, agent);
      commit('setAgent', updatedAgent);
      !suppressMessage && showSuccessMessage('Agent has been successfully Saved');
      return true;
    } catch (e) {
      showErrorMessage(handleError(e));
      return false;
    } finally {
      commit('setSaveLoading', false);
    }

  },

  async loadSampleLink({ commit }, id) {
    try {
      const link = await AgentsApi.getSampleLink(id);
      commit('setSampleLink', link);
    } catch (e) {
      showErrorMessage(handleError(e));
    }
  },

};

// mutations
const mutations = {
  setAgent(state, newAgentData) {
    state.agent = newAgentData;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setSampleLink(state, newLink) {
    state.sampleLink = newLink;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}