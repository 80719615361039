<template>
  <div id="homeval-landing-app" class="homeval-landing-app">
    <div v-if="this.$route.meta.layout">
    <component :is="this.$route.meta.layout || 'div'"></component>
    </div>
    <div v-else>
      <router-view />
    </div>

  </div>
</template>

<script>


export default {
  name: 'App',
  components: {
  },
  created: function() {
   //  this.$store.dispatch('Agent/loadAgentData', { agentId: 1 }, { root: true });
  },
}
</script>

<style lang="scss">
/*
@font-face {
  font-family: 'Metro-Sans';
  src: url("/fonts/dbc-landing/Metro-Sans-Regular.eot");
  src: url("/fonts/dbc-landing/Metro-Sans-Regular.eot") format("embedded-opentype"), url("/fonts/dbc-landing/Metro-Sans-Regular.ttf") format("truetype"), url("/fonts/dbc-landing/Metro-Sans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

 */
  // Import Main styles for this application
  @import 'assets/scss/style';
  
  label {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

.widget-container-vertical {
  .agent-panel .hv-header-card  .hv-agent-details .main-cont {
    display: flex !important;
    flex-direction: column !important;
  }

  .agent-panel {
    padding-top: 60px;
    width: 100% !important;
    padding-left: 30% !important;
  }
}

 .card {
    background: transparent !important;
  }
</style>
