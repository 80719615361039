<template>
  <footer class="bg-dark text-center text-white p-2">
      <p class="p-0 m-0">
        Listing Results: &copy; Deluxe Branded Marketing, 2000
        - {{ new Date().getFullYear() }}.
        Use is subject to  <router-link :to="{name:'Tos'}">Terms of Use</router-link>
      </p>

      <small>
        If your property is currently listed with a Realtor, please disregard this
        notice. It is not our intention to solicit the offerings of other Brokers.
      </small>

      <br />

      <small>
        v{VERSION}
      </small>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
footer a {
  color:inherit;
  text-decoration:underline;
  font-weight:bold;
}
</style>