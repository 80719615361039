import { showErrorMessage, showSuccessMessage } from '../../utils/Notification';
import { getErrorMessage } from '../../utils/ErrorHandler';


// Use it

const defaultValues = {
  agentId: '1',
  agentName: 'Agent1 Name',
  orderNumber: '1111111',
  type: 'map',
};
// initial state
const state = () => ({
  mainParams: {agentId: '', agentName:'', orderNumber: '', type: 'map'},
  loading: false,
});


// getters
const getters = {

};

// actions
const actions = {

  updateMainData({ commit }, { field, value }) {
    commit('setMainData', field, value);
  },

};

// mutations
const mutations = {
  setMainData(state, {field, value}) {
    state.mainParams = { ...state.mainParams, [field]: value };
  },
  setLoading(state, status) {
    state.loading = status;
  },
  initDefault(state){
    state.mainParams = {...defaultValues};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}