<template>
    <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/property-search">Property Search</router-link> |
        <router-link to="/result">Result</router-link> |
        <router-link to="/leads">Leads Page</router-link> |
        <router-link to="/id-widgets">Widgets</router-link> | 
        <router-link to="/order-editor">Order Flow Editor</router-link>
    </div>
</template>

<script>
export default {
    name: 'Navbar'
}
</script>