import Vue from 'vue';
import Router from 'vue-router';

//Layouts
import LayoutBox from '../layouts/LayoutBox';
import LayoutPlain from '../layouts/LayoutPlain';

const Home = () => import('../views/pages/Home');

// Views
const LandingResultPage = () => import('../views/results/LandingResultPage');
const LandingGreetingPage = () =>
  import('../views/results/LandingGreetingPage');
const IDWidgets = () => import('../views/widgets/IDWidgets');
const WidgetResult = () => import('../views/widgets/WidgetResult');
const TermsOfUsePage = () => import('../views/terms/TermsOfUsePage');
const Page404 = () => import('../views/pages/Page404');

// Views - Pages
//const Page500 = () => import('@/views/pages/Page500')
const PropertySearch = () => import('@/views/property-search/PropertySearch');
//const Register = () => import('@/views/pages/Register')

Vue.use(Router);

export default new Router({
                            mode: 'history', // "hash", // https://router.vuejs.org/api/#mode
                            linkActiveClass: 'active',
                            scrollBehavior: () => ({ y: 0 }),
                            routes: configRoutes(),
                          });

function configRoutes() {
  return [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        layout: LayoutBox,
        has_footer: true,
      },
    },
    {
      path: '/404',
      name: 'NotFound',
      component: Page404,
      meta: {
        layout: LayoutBox,
        has_footer: true,
      },
    },
    {
      path: '/term-of-use',
      name: 'Tos',
      component: TermsOfUsePage,
      meta: {
        layout: LayoutBox,
      },
    },
    {
      path: '/id-widgets',
      name: 'Widgets',
      component: IDWidgets,
      props: {
        agentId: process.env.VUE_APP_AGENT_ID,
      },
      meta: {
        layout: LayoutPlain,
      },
    },
    {
      path: '/search/:contact_id',
      name: 'Property Search',
      props: {
        props: {
          agentId: process.env.VUE_APP_AGENT_ID,
        },
        agent1: {
          FirstName: 'Agent1 Name',
          LastName: 'Agent1 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum1',
          email: 'email1@example.com',
          license: 'license1',
        },
        agent2: {
          FirstName: 'Agent2 Name',
          LastName: 'Agent2 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum2',
          email: '',
          license: 'license2',
        },
      },
      // route level code-splitting
      // this generates a separate chunk (propertysearch.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: PropertySearch,
      meta: {
        layout: LayoutBox,
        has_footer: true,
      },
    },

    {
      path: '/result/:contact_id/:campaign_id?',
      name: 'LandingResults',
      component: LandingResultPage,
      props: {
        /*
        client: {
          name: "David"
        },

         */
        // agentId: process.env.VUE_APP_AGENT_ID
      },
      meta: {
        layout: LayoutBox,
      },
    },

    {
      path: '/greeting/:contact_id/:campaign_id?',
      name: 'LandingGreeting',
      component: LandingGreetingPage,
      props: {
        agentId: '',
      },
      meta: {
        layout: LayoutBox,
      },
    },
    {
      path: '/widget/w',
      name: 'Widget',
      props: {
        layout: 'horizontal',
        agent1: {
          FirstName: 'Agent1 Name',
          LastName: 'Agent1 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum1',
          email: 'email1@example.com',
          license: 'license1',
          facebook: 'fb_agent1',
          twitter: 'twitter_agent1',
          linkedin: 'linkedin_agent1',
          primary_domain: 'example.com/agent1',
        },
        agent2: {
          FirstName: 'Agent2 Name',
          LastName: 'Agent2 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum2',
          email: '',
          license: 'license2',
          facebook: 'fb_agent2',
          twitter: 'twitter_agent2',
          linkedin: 'linkedin_agent2',
          primary_domain: 'example.com/agent2',
        },
      },
      component: WidgetResult,
      meta: {
        layout: LayoutPlain,
      },
    },
    {
      path: '/widget/w/horizontal/:id',
      name: 'Widget Horizontal',
      props: {
        layout: 'horizontal',
      },
      component: WidgetResult,
      meta: {
        layout: LayoutPlain,
      },
    },
    {
      path: '/widget/w/vertical/:id',
      name: 'Widget Vertical',
      props: {
        layout: 'vertical',
        agent1: {
          FirstName: 'Agent1 Name',
          LastName: 'Agent1 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum1',
          email: 'email1@example.com',
          license: 'license1',
          facebook: 'fb_agent1',
          twitter: 'twitter_agent1',
          linkedin: 'linkedin_agent1',
          primary_domain: 'example.com/agent1',
        },
        agent2: {
          FirstName: 'Agent2 Name',
          LastName: 'Agent2 LastName',
          image: 'https://via.placeholder.com/150x150',
          phone: 'phonenum2',
          email: '',
          license: 'license2',
          facebook: 'fb_agent2',
          twitter: 'twitter_agent2',
          linkedin: 'linkedin_agent2',
          primary_domain: 'example.com/agent2',
        },
      },
      component: WidgetResult,
      meta: {
        layout: LayoutPlain,
      },
    },
    /*    {
      path: "*",
      redirect: "/404",
    },*/
  ];
}
