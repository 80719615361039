import ListsApi from '../../api/lists'
import { showErrorMessage, showSuccessMessage } from '@/utils/Notification';
import { handleError } from '@/utils/ErrorHandler';

// initial state
const state = () => ({
  list: {
    id: 0,
    office: '',
    agent: '',
    use_listing: '1',
    address: '',
    city: '',
    state: '',
    zip: '',
    price: '',
    sqft: '',
    beds: '',
    baths: '',
    status: '',
    photo_url: '',
    created_at: '',
    is_archived: 0,
    mls_src_id: '',
  },
  loading: false,
  lists: undefined,
  saveLoading: false,
});


// getters
const getters = {};

// actions
const actions = {
  async loadListsByAgent({ commit, rootGetters }) {
    const { id: agentId } = rootGetters['Agent/getAgent'];
    commit('setLists', undefined);
    commit('setLoading', true);
    // run api call
    if (agentId) {
      const lists = await ListsApi.loadListsByAgentId(agentId);
      commit('setLists', lists);
    }

    commit('setLoading', false);
  },

  loadFromList({ state: { lists } }, id) {
    if (!lists) return {};

    const list = lists.find(({ id: _id }) => _id === id);

    return list || {};
  },

  async archiveList({ dispatch, state }, { id, status }) {
    const result = await dispatch('updateListData', { list: { id, is_archived: +status }, suppressMessage: true });
    result && showSuccessMessage(status ? 'List was Archived Successfully' : 'List was Restored Successfully');
    return result;
  },


  async updateListingStatus({ dispatch }, { id, status }) {
    const result = await dispatch('updateListData', { list: { id, use_listing: +status }, suppressMessage: true });
    // result && showSuccessMessage('Email was updated Successfully');
    return result;
  },


  async updateListData({ commit, state }, { list, suppressMessage = false }) {

    commit('setSaveLoading', true);
    // run api call
    try {
      const updatedList = await ListsApi.updateListDataById(list.id, list);
      commit('setList', updatedList);
      !suppressMessage && showSuccessMessage('List has been successfully Saved');
      return true;
    } catch (e) {
      showErrorMessage(handleError(e));
      return false;
    } finally {
      commit('setSaveLoading', false);
    }

  },

  async loadListById({ state, commit }, id) {

    const { lists } = state;

    commit('setList', lists.find(({ id: lId }) => lId === id));
  },

  async updateListImage({ commit, state }, { id, file }) {
    try {
      commit('setSaveLoading', true);
      const updatedList = await ListsApi.updateImage(id, file);
      const newLists = state.lists.map((list) => {
        if (list.id === id) return updatedList;
        return list;
      });
      commit('setLists', newLists);
      return newLists;
    } catch (e) {
      showErrorMessage(handleError(e));
      return false;
    } finally {
      commit('setSaveLoading', false);
    }
  },

};

// mutations
const mutations = {
  setList(state, newListData) {
    state.list = newListData;
    const { id: updId } = newListData;
    const newLists = state.lists.map(list => {
      const { id } = list;

      if (id === updId) {
        return newListData;
      }
      return list;
    });
    state.lists = [...newLists];
  },
  setLists(state, newListsData) {
    state.lists = newListsData ? [...newListsData] : undefined;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};