import BaseApi from '@/api/base-api';


class AgentApi extends BaseApi {
  async loadAgentDataById(id) {
      const response =   await this.Api.get('homevaldmgapi', `/api/agents/${id}`);
      console.log('loadAgentDataById response', response);
      return response.data.data.agent;
      return response.data.agent;
  }

  async updateAgentDataById(id, agent) {
    const { data: { agent: updAgent } } = await this.Api.put('homevaldmgapi', `/api/agents/${id}`, {body:agent});

    return updAgent;
  }

  async getSampleLink(agentId){
    const { data: { link } } = await this.Api.get('homevaldmgapi', `/api/agents/${agentId}/sample-link`);

    return link;
  }
}


export default new AgentApi();