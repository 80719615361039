import TrackingApi from '../../api/tracking';

const state = () => ({
    metrics_from: '',
    metrics_to: '',
    metrics_custom: '',
    visits_data: [],
    search_data: []
});

// getters
const getters = {
};

// actions
const actions = {
    async loadMetrics(context, {agent_id, date_from, date_to , delta} ) {
        console.log ('Dashboard/loadMetrics action called');
        
        await context.dispatch('Dashboard/getMetricsPageViews', { 'agent_id': agent_id, 'date_from': date_from, 'date_to': date_to, 'delta': delta }, {root: true});
        await context.dispatch('Dashboard/getMetricsSearches', { 'agent_id': agent_id, 'date_from': date_from, 'date_to': date_to, 'delta': delta }, {root: true});
    },
    async getMetricsPageViews( context , {agent_id, date_from, date_to , delta} ) {
        console.log( 'Dashboard/getMetricsPageViews called ' );
        delta = delta || 'day';
        await TrackingApi.getVisitsCount (agent_id, date_from, date_to, delta ).then(
            function ( res ) {
                if ( 'undefined' != typeof res.success) {
                    //Parsing the resulting data to a vector of date=>value pairs
                    const visits = [];
                    
                    const resdata = JSON.parse(res.data);

                    resdata.forEach( function(item){
                        console.log ( 'callback for result data processing ', item );
                        //The incoming item is string like "{visits_date=2020-12-02 00:00:00.000000000, visit_count=6}"
                        const regex = /\{event_date=(.*),\sevent_count=(.*)\}/gm;
                        const matches = [...item.matchAll(regex)];
                        console.log( matches );
                        if ( matches.length ) {
                            const dt = new Date(matches[0][1]);
                            visits.push( { x: dt , y: matches[0][2] } );
                        } 
                    });

                    context.commit('setVisitsData', visits);
                }
            }
        );
        
    },
    async getMetricsSearches(  context, {agent_id, date_from, date_to , delta} ) {
        delta = delta || 'day';
        console.log( 'Dashboard/getMetricsSearches called ' );
        await TrackingApi.getSearchCount (agent_id, date_from, date_to, delta ).then(
            function ( res ) {
                if ( 'undefined' != typeof res.success) {
                    //Parsing the resulting data to a vector of date=>value pairs
                    const searches = [];

                    const resdata = JSON.parse(res.data);

                    resdata.forEach( function(item){
                        console.log ( 'callback for result data processing ', item );
                        //The incoming item is string like "{visits_date=2020-12-02 00:00:00.000000000, visit_count=6}"
                        const regex = /\{event_date=(.*),\sevent_count=(.*)\}/gm;
                        const matches = [...item.matchAll(regex)];
                        if ( matches.length ) {
                            const dt = new Date(matches[0][1]);
                            searches.push( { x: dt , y: matches[0][2] } );
                        } 
                    });

                    context.commit('setSearchData', searches);
                }
            }
        );
    }
};

// mutations
const mutations = {
    'setVisitsData' : (state, val) => {
        console.log ( 'setVisitisData()', state, val );
        state.visits_data = val;
        console.log ( state.visits_data );
    },
    'setSearchData' : (state, val) => {
        state.search_data = val;
    },
    'setMetricsFrom' : (state, val) => {
        state.metrics_from = val;
    },
    'setMetricsTo' : (state, val) => {
        state.metrics_to = val;
    },
    'setMetricsCustom' : (state, val) => {
        state.metrics_custom = val;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }