import axios from 'axios';

const axiosConfig = {
  headers: { "X-Api-Key": process.env.VUE_APP_API_GATEWAY_TOKEN }
};
class API {

  constructor(url) {
    //this.axios = new axios(axiosConfig);
    this.baseUrl = url || process.env.VUE_APP_API_GATEWAY;
  }

  getApiUrl( urlPath ) {
    return this.baseUrl + urlPath;
  }

  async get(apiName, path, init) {
    return axios( {method: 'get', url: this.getApiUrl(path),headers: axiosConfig.headers}); //.get(this.getApiUrl(path), this.axiosConfig);
  }

  post(apiName, path, init) {
    return axios({method:'post', url: this.getApiUrl(path), headers: axiosConfig.headers, data: init.body});
    //return axios.post(this.getApiUrl(path), init, this.axiosConfig);
  }

  put(apiName, path, init) {
    return axios({method:'put', url: this.getApiUrl(path), headers: axiosConfig.headers, data: init.body });
    //return axios.put(this.getApiUrl(path), init, this.axiosConfig);
  }

  patch(apiName, path, init) {
    return axios({method:'patch', url: this.getApiUrl(path), headers: axiosConfig.headers, data: init.body });
    //return axios.patch(this.getApiUrl(path), init, this.axiosConfig);
  }

  del(apiName, path, init) {
    return axios({method:'delete', url: this.getApiUrl(path), headers: axiosConfig.headers });
    //return axios.delete(this.getApiUrl(path), this.axiosConfig);
  }

  head(apiName, path, init) {
    return axios({method:'head', url: this.getApiUrl(path), headers: axiosConfig.headers});
    //return axios.head(this.getApiUrl(path), this.axiosConfig);
  }
}

class BaseApi {

  constructor(baseUrl) {
    this.API = this.Api = new API(baseUrl);
  }

}

export default BaseApi;
