import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/store'
import * as VueGoogleMaps from 'vue2-google-maps'
import { ClientTable } from 'vue-tables-2';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuelidate from 'vuelidate'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare, faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faUserSecret, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons'
import VueMapbox from 'vue-mapbox';
import Mapbox from 'mapbox-gl';
import numeral from 'numeral';
import VueMeta from 'vue-meta';
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  // You can set your default options here
};


library.add(faUserSecret);
library.add(faFacebookSquare);
library.add(faTwitterSquare);
library.add(faLinkedin);
library.add(faGlobeAmericas);

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0'); // displaying other groupings/separators is possible, look at the docs
});
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.performance = true
Vue.use(Toast, options);
Vue.use(CoreuiVue)
Vue.use(Vuelidate)
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueMeta)

Vue.use(VueMapbox, { mapboxgl: Mapbox });

Vue.use(ClientTable, {}, false, 'bootstrap4');

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCPJpjD-qcR_yIxJnS8maR5W9KB0E3EzYI',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});


new Vue({
          el: '#app',
          router,
          store,
          icons,
          template: '<App/>',
          components: {
            App,
          },
        });
