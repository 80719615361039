import _ from 'underscore';
import lodash from 'lodash';

import BaseApi from './base-api';
import axios from 'axios';

class PropertyApi extends BaseApi {
  async getOtherSales(property) {
    const {
      data: {
        response: { code, data },
      },
    } = await this.Api.post(
      'homevaldmgapi',
      `/api/property/zillow/other-sales`,
      { body: property },
    );
    const sales = code === 'success' ? data : [];
    return sales;
  }

  async getPropertyDetails(address, city, state, zip) {
    const {
      data: {
        response: { data },
      },
    } = await this.Api.post('homevaldmgapi', `/api/property/details`, {
      body: { address, city, state, zip },
    });
    return data;
  }

  async getValuations(property) {
    const address =
      property.street_address_1 +
      (property.street_address_2 ? ` ${property.street_address_2}` : '');

    const getZillowValuation = new Promise(resolve => {
      const citystatezip = `${property.city} ${property.state} ${property.zip}`;
      this.Api.post('homevaldmgapi', `/api/property/zillow/estimate`, {
        body: {
          address,
          citystatezip,
          lon: property.geocode.lng,
          lat: property.geocode.lat,
        },
      })
        .then(res => {
          if (res.data.response) {
            const { code, data } = res.data.response;
            const estimate = code === 'success' ? data : null;
            resolve(estimate);
          } else {
            resolve(null);
          }
        })
        .catch(err => {
          console.error(err);
          resolve(null);
        });
    });

    // const getEppraisalValuation = new Promise(resolve => {
    //   const citystate = `${property.city} ${property.state}`;
    //   this.Api.post('homevaldmgapi', `/api/property/eppraisal/estimate`, {
    //     body: { address, citystate, zip: property.zip },
    //   })
    //     .then(res => {
    //       resolve(lodash.isEmpty(res.data.response) ? null : res.data.response);
    //     })
    //     .catch(err => {
    //       console.error(err);
    //       resolve(null);
    //     });
    // });

    const getRealtyMoleValuation = new Promise(resolve => {
      const body = {
        address: property.street_address_1,
        city: property.city,
        state: property.state,
        zip: property.zip,
      };

      this.Api.post('homevaldmgapi', `/api/property/realty-mole/estimate`, {
        body,
      })
        .then(res => {
          resolve(lodash.isEmpty(res.data.response) ? null : res.data.response);
        })
        .catch(err => {
          console.error(err);
          resolve(null);
        });
    });

    const getEstatedValuation = new Promise(resolve => {
      const body = {
        address: property.street_address_1,
        city: property.city,
        state: property.state,
        zip: property.zip,
      };

      this.Api.post('homevaldmgapi', `/api/property/estated/estimate`, { body })
        .then(res => {
          resolve(res.data.response || null);
        })
        .catch(err => {
          console.error(err);
          resolve(null);
        });
    });

    const estimates = await Promise
      //.all([getZillowValuation, getEppraisalValuation, getEstatedValuation]);
      .all([
             getZillowValuation,
             getEstatedValuation,
             getRealtyMoleValuation,
           ]);

    //const [zillow, eppraisal, estated] = estimates;
    const [zillow, estated, realtyMole] = estimates;
    //let valuations = { zillow, eppraisal, estated };
    let valuations = { zillow, estated, realtyMole };
    _.mapObject(valuations, (valuation, key) => {
      if (_.isEmpty(valuation) || _.isEmpty(valuation.estimate))
        valuations = _.omit(valuations, key);
    });

    // Ensure homeWorthEstimate within 20% of zillowEstimate to show
    // const zillowEstimate = lodash.get(zillow, 'estimate.amount');
    // const attomEstimate = lodash.get(attom, 'estimate.amount');
    // if (zillowEstimate && attomEstimate
    //     && (attomEstimate < zillowEstimate * 0.8 || attomEstimate > zillowEstimate * 1.2)) {
    //     valuations = _.omit(valuations, 'attom');
    // }
    const estatedEstimate = lodash.get(estated, 'estimate.amount');
    /*if (zillowEstimate && estatedEstimate
      && (estatedEstimate < zillowEstimate * 0.8 || estatedEstimate > zillowEstimate * 1.2)) {
      //valuations = _.omit(valuations, 'estated');
    }*/

    return valuations;
  }

  async searchAddress(contact_id, address) {
    // const action = 'searchAddress';
    const {
      status,
      error,
      data: { response: { exact, message } } = {},
    } = await this.Api.post(
      'homevaldmgapi',
      `/api/property/address/search/${contact_id}`,
      { body: { address } },
    );

    if (status === 'err') throw new Error(error);
    const type = exact ? 'success' : 'warning';
    return { type, message };
  }

  async loadFeatures(agentId) {

    const {
      data: { data: { lists = [] } } = {},
    } = await this.Api.get('homevaldmgapi', `/api/lists/features/agent/${agentId}`);

    return lists;
  }

  getAddressHash(property) {
    return [property.street_address_1, property.street_address_2, property.city, property.state, property.zip].join('');
  }
}

export default new PropertyApi();
