import TrackingService from '../../api/tracking';
import ContactApi from '../../api/contact';
import { showErrorMessage, showSuccessMessage } from '../../utils/Notification';
import { getErrorMessage } from '../../utils/ErrorHandler';

// initial state
const state = () => ({
  contact: undefined,
  loading: false,
  saveLoading: false,
  campaignId: 0,
});


// getters
const getters = {
  getContact(state) {
    const { contact } = state;
    return contact;
  },
  getContactAgentId(state) {
    const { contact } = state;
    return contact ? contact.agent_id : '';
  },
  setCampaignId({ commit }, cId) {
    commit('setCampaignId', cId);
  },
};

// actions
const actions = {

  setCampaignId({ commit }, cId) {
    commit('setCampaignId', cId);
  },
  async recordVisit({ commit, state, dispatch }, contactId) {

    await ContactApi.recordVisit(contactId);
    return true;
  },
  async loadContactData({ commit, state, dispatch }, id) {

    let contact;
    commit('setLoading', true);
    try {
      // run api call
      contact = await ContactApi.loadContactDataById(id);
      console.log('loadContactData contact : ', contact);
      commit('setContact', contact);
    } catch (e) {
      showErrorMessage('Contact Not Found');
      console.log(e);
      commit('setContact', {});
    }

    commit('setLoading', false);

    if (contact) {
      const { agent_id: agentId } = contact;
      console.log('loadContactdata agent_id : ', agentId);

      if (agentId) {
        dispatch('Agent/loadAgentData', { agentId }, { root: true });
      }

    }

    return contact;

  },

  async saveAppointment({ commit, state, dispatch }, { contactId, type, data, campaignId }) {

    commit('setLoading', true);

    try {
      await ContactApi.saveAppointment(contactId, type, data);
      showSuccessMessage('Thank you for contacting me! I will be in touch.');
      TrackingService.addGetStartedSubmitted(data.agent_id, contactId, campaignId, data).then();
    } catch (e) {
      showErrorMessage(getErrorMessage(e));
      return false;
    } finally {
      commit('setLoading', false);
    }
    return true;
  },


  updateAddress({ state, commit }, address) {
    const { contact } = state;
    contact.street_address_1 = address;
    commit('setContact', contact);
  },

  async updateEmail({ state, commit }, email) {

    commit('setSaveLoading', true);

    const { contact } = state;

    contact.lead_contact_email_address = email;


    try {
      await ContactApi.updateAddress(contact.id, contact);
      commit('setContact', contact);
      // showSuccessMessage('Thank you for contacting me! I will be in touch.');
    } catch (e) {
      showErrorMessage(getErrorMessage(e));
      return false;
    } finally {
      commit('setSaveLoading', false);
    }
    return true;

  },

};

// mutations
const mutations = {
  setContact(state, newContactData) {
    state.contact = { ...newContactData };
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setCampaignId(state, newCId) {
    state.campaignId = newCId;
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}