import BaseApi from '@/api/base-api';

// import axios from 'axios';
// base
const addressLine = {
"First_Name":"FirstName",
"Last_Name":"LastName",
  "Name":"FirstName LastName", // # combined like "FirstName LastName"
  "Address1":"448 N Witchduck Rd",
  "Address2":"Virginia Beach VA 23462-1943",
  "City":"",
  "State":"",
  "Zip":"",
  "CSZ":"",// # Combined like "City, State ZIP"

// # postal and production added
  "Carr_RTE":"",
  "Postnet":"",
  "Planet":"",
  "Seq_no":"",
  "Package":"",
  "Tray":"",
  "IMB":"",
  "Record":"",
  "Order":"",

// # custom additional, defined on product (common example)
  "First":"", // # automatic from First_Name
  "Last":"", // # automatic from First_Name # automatic from Last_Name
  "Site_Address":"", // # automatic from First_Name # Gobig, Absentee data
  "Site_City":"", // # automatic from First_Name # Gobig, Absentee data
  "Site_State":"", // # automatic from First_Name # Gobig, Absentee data
  "Site_Zip":"", // # automatic from First_Name # Gobig, Absentee data
  "custom_field_1":"", // # automatic from First_Name
  "custom_field_2":"", // # automatic from First_Name
  "Return_Address1":"", // # automatic from First_Name # return address is usually at the order level, not record level
  "Return_Address2":"", // # automatic from First_Name # return address is usually at the order level, not record level
  "Return_CSZ":"", // # automatic from First_Name # return address is usually at the order level, not record level
};

class PostalListApi extends BaseApi {
  async loadPostalListsByAgentId(agentId) {
    // const { data: { lists } } = await this.Api.get('homevaldmgapi', `/api/lists/agent/${agentId}`);

    const lists = [
      { id: 1, name: '[Farm 0001] 1702 Port Strtrtrtr (CIS) (500)' },
      { id: 2, name: '[Farm 0023] 1702 Port  (CIS) (500)' },
      { id: 3, name: '[Farm 0042] 1702 Port Strtrtrtr (CIS) (500)' },
      { id: 4, name: '[Farm 0004] 1702 Port Strtrtrtr (CIS) (500)' },
      { id: 5, name: '[Farm 0067] 1702 Port Strtrtrtr (CIS) (500)' },
      { id: 6, name: '[Farm 0041] 1702 Port  (CIS) (500)' },
      { id: 7, name: '[Farm 0028] 1702 Port Strtrtrtr (CIS) (500)' },
      { id: 8, name: '[Farm 0037] 1702 Port  (CIS) (500)' },
    ];

    return Promise.resolve(lists);
  }
}


export default new PostalListApi();