<template>
    <div class="layout-box">
        <div v-if="this.$route.meta.has_navbar" >
            <Navbar />
        </div>

        <div>
            <router-view />
        </div>

        <div v-if="this.$route.meta.has_footer" >
            <Footer />
        </div>

    </div>
</template>
<script>
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';

export default {
    name: 'LayoutBox',
    components: {
        'Navbar': Navbar,
        'Footer': Footer
    }
}
</script>