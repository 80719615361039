import { showErrorMessage, showSuccessMessage } from '../../utils/Notification';
import { getErrorMessage } from '../../utils/ErrorHandler';


// Use it

// initial state
const state = () => ({
  order: {
    selectedLists: [],
    listsOptions: {
      readyToVerif: false,
      readyToContinue: false,
      needReVerif: false,
      listStatus: 'No existing list',
      verificationInProcess: false,
    },
  },
  loading: false,
});


// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {

  setLoading(state, status) {
    state.loading = status;
  },
  updateListsStatus(state, {field, value}){
    state.order.listsOptions = {...state.order.listsOptions, [field]: value};
  },
  togglePostalList(state, element) {

    const { selectedLists } = state.order;
    if (Array.isArray(element)) {
      state.order.selectedLists = [...element];
      return;
    }
    const isExistsIndex = selectedLists.findIndex(({ id }) => id === element.id);
    if (isExistsIndex > -1) {
      selectedLists.splice(isExistsIndex, 1);
    } else {
      selectedLists.push(element);
    }
    state.order.selectedLists = [...selectedLists];
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}