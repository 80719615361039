import TrackingApi from '../../api/tracking';


const state = () => ({
    
});

// getters
const getters = {
    
};

// actions
const actions = {
    async initTrackingDatabase() {
        await TrackingApi.initTrackingDatabase();
    },
    async addSearch( context, { agent_id, contact_id, address, campaign_id }) {
        await TrackingApi.addSearch( agent_id, contact_id, address, campaign_id );
    },
    async addPageView(context,{agentId, contactId, campaignId, contact}) {
        await TrackingApi.addPageView( agentId,  contactId, campaignId, contact);
    }
};

// mutations
const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }