import _ from 'lodash';
import PropertyApi from '../../api/property';
import { handleError } from '../../utils/ErrorHandler';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../../utils/Notification';

// initial state
const state = () => ({
  property: {},
  loading: false,
  sales: [],
  valuations: [],
  activePropertyHash: '',
  error: undefined,
  searchLoader: false,
  showAddressWnd : false,
})


// getters
const getters = {}

// actions
const actions = {

  async getOtherSales({ commit }, zpid) {
    commit('setSales', []);
    if (zpid) {
      commit('setLoading', true);

      try {
        commit('setError', undefined);
        const sales = await PropertyApi.getOtherSales(zpid);
        commit('setSales', sales);
      } catch (e) {
        commit('setError', handleError(e));
      } finally {
        commit('setLoading', false);
      }
    }
  },

  async getValuations({ commit, state, dispatch }, property) {

    if (state.valuations && state.activePropertyHash === PropertyApi.getAddressHash(property)) {
      return;
    }


    commit('setError', undefined);
    commit('setValuations', []);
    commit('setLoading', true);
    try {
      const valuations = await PropertyApi.getValuations(property);
      commit('setValuations', valuations);
      if (_.isEmpty(valuations)){
        commit('setAddressWndStatus', true);
        commit('setActivePropertyHash', PropertyApi.getAddressHash(property));
      }
    } catch (e) {
      console.error(e);
      commit('setAddressWndStatus', true);
      commit('setError', handleError(e));
    } finally {
      commit('setLoading', false);
    }
  },


  async searchAddress({ commit, state, dispatch, rootGetters }, { contact_id, address }) {
    commit('setLoading', true);
    commit('setSearchLoading', true);

    try {
      const { type, message } = await PropertyApi.searchAddress(contact_id, address);
      // commit('setStatus', )
      if (type === 'success'){
        // dispatch('Contact/updateAddress', address, { root: true });
        await dispatch('Contact/loadContactData', contact_id, { root: true });
        await dispatch('getValuations', rootGetters['Contact/getContact']);
        showSuccessMessage(message);
      } else {
        showWarningMessage(message);
      }

    } catch (e) {
      showErrorMessage(handleError(e));
      commit('setError', handleError(e));
    } finally {
      commit('setLoading', false);
      commit('setSearchLoading', false);
    }
  },
  resetStatus({ commit }) {
    //dispatch(setStatus(action));
    //commit('setStatus', );
  },

}

// mutations
const mutations = {
  setSales(state, newSalesData) {
    state.sales = [...newSalesData];
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSearchLoading(state, status) {
    state.searchLoader = status;
  },
  setError(state, newError) {
    state.error = newError;
  },
  setValuations(state, newVals) {
    state.valuations = { ...newVals };
  },
  setAddressWndStatus(state, newVal){
    state.showAddressWnd = newVal;
  },
  setActivePropertyHash(state, hash) {
    state.activePropertyHash = hash;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
