import { showErrorMessage, showSuccessMessage } from '../../utils/Notification';
import { getErrorMessage } from '../../utils/ErrorHandler';
import postalLists from '../../api/postalLists';


// Use it

// initial state
const state = () => ({
  postalLists: undefined,
  loading: false,
  selectedLists: [],
});


// getters
const getters = {};

// actions
const actions = {
  /*
    updateMainData({ commit }, { field, value }) {
      commit('setMainData', field, value);
    },
  */
  async loadPostalLists({ commit, state, rootGetters }, agentId) {
    const { id: agentIdRomStore } = rootGetters['Agent/getAgent'];

      commit('setLoading', true);
      const list = await postalLists.loadPostalListsByAgentId(agentIdRomStore);

      commit('setPostalList', list);
      commit('setLoading', false);
  },
};

// mutations
const mutations = {
  setPostalList(state, list) {
    state.postalLists = list;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  togglePostalList(state, tId) {
    const { selectedLists, postalLists } = state;
    if (Array.isArray(tId)){
      state.selectedLists = !tId.length ? [] : [...postalLists];
      return;
    }
    const isExistsIndex = selectedLists.findIndex(({ id }) => id === tId);
    if (isExistsIndex > -1) {
      selectedLists.splice(isExistsIndex, 1);
    } else {
      selectedLists.push(postalLists.find(({ id }) => id === tId));
    }
    state.selectedLists = [ ...selectedLists ];
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}