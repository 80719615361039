import { ClientJS } from 'clientjs';
import BaseApi from './base-api';

const VISIT_TYPE = {
  CONTACT: 'RESULT_VIEW',
  RUN_SEARCH: 'RUN_SEARCH',
  GREETING: 'GREETING_VIEW',
  GET_STARTED_LANDING: 'GET_STARTED_LANDING',
  GET_STARTED_SUBMITTED: 'GET_STARTED_SUBMITTED',
  NEW_AGENT_WIDGET_LEAD: 'NEW_AGENT_WIDGET_LEAD',
};

const VISITS_URL_TYPE_MAP = { result: VISIT_TYPE.CONTACT, greeting: VISIT_TYPE.GREETING };

class TrackingApi extends BaseApi {
  async initTrackingDatabase() {
    return this.Api.get('homevaldmgapi', '/api/tracking/init', { body: {}, headers: {} });
  }

  async addSearch(agent_id, contact_id, address, campaign_id = '') {
    return this.Api.post('homevaldmgapi', '/api/tracking/addSearch', {
      body: {
        agent_id: agent_id,
        contact_id: contact_id,
        campaign_id: campaign_id,
        action: VISIT_TYPE.RUN_SEARCH,
        address: address,
      }, headers: {},
    });
  }

  async addPageView(agent_id, contactId, campaignId, contact) {
    const address = this._getAddressFromContact(contact || {});

    const type = this._getPageType();

    if (type) {
      const client = new ClientJS();

      return this.Api.post('homevaldmgapi', '/api/tracking/addVisits', {
        body: {
          action: type.type,
          contact_id: type.id,
          'agent_id': agent_id,
          fingerprint: client.getFingerprint(),
          campaign_id: campaignId || '',
          address,
        }, headers: {},
      });
    }
  }


  // NEW_AGENT_WIDGET_LEAD
  async addAgentNewLead(agent_id, contactId, contact) {
    const address = this._getAddressFromContact(contact || {});
    const client = new ClientJS();
    return this.Api.post('homevaldmgapi', '/api/tracking/addVisits', {
      body: {
        action: VISIT_TYPE.NEW_AGENT_WIDGET_LEAD,
        contact_id: contactId,
        'agent_id': agent_id,
        fingerprint: client.getFingerprint(),
        campaign_id: '0',
        address,
      }, headers: {},
    });

  }


  async addGetStarted(agent_id, contactId, campaignId = '', contact) {
    const address = this._getAddressFromContact(contact || {});
    const client = new ClientJS();
    return this.Api.post('homevaldmgapi', '/api/tracking/addVisits', {
      body: {
        action: VISIT_TYPE.GET_STARTED_LANDING,
        contact_id: contactId,
        'agent_id': agent_id,
        fingerprint: client.getFingerprint(),
        campaign_id: campaignId || '',
        address,
      }, headers: {},
    });

  }

  async addGetStartedSubmitted(agent_id, contactId, campaignId = '', contact) {
    const address = this._getAddressFromContact(contact || {});
    const client = new ClientJS();
    return this.Api.post('homevaldmgapi', '/api/tracking/addVisits', {
      body: {
        action: VISIT_TYPE.GET_STARTED_SUBMITTED,
        contact_id: contactId,
        'agent_id': agent_id,
        fingerprint: client.getFingerprint(),
        campaign_id: campaignId || '',
        address,
      }, headers: {},
    });

  }

  async getSearchCount(agent_id, date_from, date_to, delta) {
    return this.Api.post('homevaldmgapi', '/api/tracking/getSearchCount', {
      body: {
        'agent_id': agent_id,
        'date_from': date_from,
        'date_to': date_to,
        'delta': delta,
      },
      headers: {},
    });
  }

  async getVisitsCount(agent_id, date_from, date_to, delta) {
    return this.Api.post('homevaldmgapi', '/api/tracking/getVisitsCount', {
      body: {
        'agent_id': agent_id,
        'date_from': date_from,
        'date_to': date_to,
        'delta': delta,
      },
      headers: {},
    });
  }

  _getPageType() {
    const url = window.location.href;
    let type = '';
    let id = '';
    const params = {};
    const typeRegexp = /\/(result|search|greeting)\/([A-Za-z0-9]+)/;

    let result = null;
    if (result = url.match(typeRegexp)) {
      ([, type, id] = result);
    }


    if (type) {
      const visitType = VISITS_URL_TYPE_MAP[type];
      return { type: visitType, id };
    }
  }

  _getAddressFromContact(contact) {
    return [contact.street_address_1 || '', contact.city || '', contact.state || '', contact.zip || ''].join(', ');
  }
}

export default new TrackingApi();